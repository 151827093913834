.FiltrationType {
  background: #ebebeb;
}

.FiltrationType-styles {
  display: flex;
  flex-wrap: wrap;
}

.FiltrationType-heading {
  background: #797979;
  color: #fff;
  font-size: 1.5rem;
  height: 3.5rem;
  line-height: 3.5rem;
  margin: 0;
  padding-left: 1.5rem;
}
