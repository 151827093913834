.Home-parfit-toolkit {
  width: 16.5rem;
}

.Home-link {
  font-weight: inherit;
  text-decoration: none;
}

.Home-link h2 {
  margin: 2rem 0 0.5rem;
}

.Home-link-description {
  border-bottom: 1px solid #FA9E0B;
  padding: 0.5rem 7.5rem 1rem 0;
  position: relative;
}

.Home-link-description:hover img,
.Home-link-description:focus img {
  opacity: 0.85;
}

.Home-link-description img {
  bottom: 0;
  height: 3.75rem;
  position: absolute;
  right: 0;
}

@media screen and (min-width: 1200px) {
  .Home-parfit-toolkit {
    width: 21.75rem;
  }
}
