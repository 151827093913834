.AddField {
  margin: 0.75rem 0;
}

.AddFieldButton {
  background-color: #f5f5f5;
  border: none;
  border-radius: 0.25rem;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  padding: 0.375rem;
  width: 100%;
}

.AddFieldButton:hover, .AddFieldButton:focus {
  background-color: #ebebeb;
}

.AddFieldButton-plus {
  line-height: 1em;
  margin: 0 auto;
}
