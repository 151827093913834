.LabeledInputField textarea {
  background-color: #f5f5f5;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  box-sizing: border-box;
  color: #000;
  font-size: 1rem;
  height: 7.3333rem;
  padding: 1rem;
  width: 100%;
}

.LabeledInputField textarea:hover,
.LabeledInputField textarea:focus {
  background-color: #ebebeb;
}
