.EnquirySent {
  background: #333;
  border-radius: .25rem;
  color: #fff;
  padding: 1.5rem;
}

.EnquirySent p {
  margin: 0;
}
