.PrimaryPanel {
  background: #fff;
  box-sizing: border-box;
  min-height: calc(50vh - 1.875rem);
  padding: 1.6667rem;
}

@media screen and (min-width: 768px) {
  .PrimaryPanel {
    padding: 3.5rem 2rem 2rem 5.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .PrimaryPanel {
    float: left;
    height: calc(100vh - 45px);
    overflow-y: auto;
    width: 50%;
  }
}
