.SecondaryPanel {
  background: #fff;
  padding: 1rem;
}

@media screen and (min-width: 768px) {
  .SecondaryPanel {
    padding: 3.5rem 2rem 2rem 5.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .SecondaryPanel {
    border-left: 1px solid #F5F5F5;
    width: 50%;
  }
}
