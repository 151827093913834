.FormGroup {
  border: 1px solid #E0E0E0;
  border-left: none;
  border-right: none;
  margin: 1rem 0;
  padding: 1rem 0;
}

.FormGroup-highlighted {
  border: 1px solid #ffb91d;
  border-left: none;
  border-right: none;
}

.FormGroup > h2 {
  margin-top: 0;
}

@media screen and (min-width: 1200px) {
  .FormGroup {
    border: 1px solid #E0E0E0;
    border-radius: 0.833rem;
    padding: 1rem 2.667rem;
  }

  .FormGroup-highlighted {
    border: 1px solid #ffb91d;
  }
}
