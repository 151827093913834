.ViewButton.DefaultButton {
  margin-top: 0;
  max-width: 10rem;
  padding: 0 0.5rem;
  white-space: nowrap;
}

.ViewButton img  {
  height: 16px;
  vertical-align: middle;
}

.ViewButton.WideButton {
  display: flex;
  text-align: left;
  padding: 0 1rem;
  max-width: none;
}

.WideButton img {
}

.WideButton .ViewButton-text {
  flex-basis: 67%;
}

.WideButton .ViewButton-img {
  flex-basis: 33%;
  text-align: right;
}
