.NarrowPanel {
  background: #fff;
  padding: 1.6667rem;
}

@media screen and (min-width: 768px) {
  .NarrowPanel {
    padding: 3.5rem 2rem 2rem 5.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .NarrowPanel {
    box-sizing: border-box;
    float: left;
    min-height: calc(100vh - 3.75rem);
    padding-left: 5.5rem;
    padding-top: 2.75rem;
    width: 25%;
  }
}
