.Notification-title {
  border-bottom: 1px solid #E0E0E0;
  font-weight: bold;
  margin-bottom: 0.25rem;
  padding-bottom: 0.5rem;
}

ul.NotificationList {
  margin-left: 0;
}
