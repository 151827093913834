.Loader {
  animation: spin 2s linear infinite;
  border: 1rem solid #f3f3f3;
  border-top: 1rem solid #ffb91d;
  border-radius: 50%;
  height: 5rem;
  margin: 1rem auto;
  width: 5rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
