.Style {
  background: #fff;
  border: none;
  border-right: 1px solid #ebebeb;
  box-sizing: border-box;
  cursor: pointer;
  flex-basis: 50%;
  min-height: 10rem;
  outline: none;
  padding: 1rem 1rem 0;
  position: relative;
  text-decoration: none;
}

.Style-name {
  font-weight: bold;
  height: 2.5rem;
}

.Style-arrow {
  background: #FFB91D;
  bottom: 0;
  line-height: 2.5rem;
  height: 2.5rem;
  position: absolute;
  right: 0;
  text-align: center;
  width: 2.5rem;
}

.Style-arrow img {
  width: 1rem;
}

.Style-image {
  margin: 1rem auto 0.25rem auto;
  width: 80%;
}

.Style-image img {
  max-width: 100%;
}

.Style:hover .Style-arrow,
.Style:focus .Style-arrow {
  background: #FA9E0B;
}

@media screen and (min-width: 475px) {
  .Style {
    flex-basis: 33.3333%;
  }
}

@media screen and (min-width: 960px) {
  .Style {
    flex-basis: 25%;
  }
}

@media screen and (min-width: 1200px) {
  .Style {
    flex-basis: 20%;
  }
}

/*

TODO: Remove if Electron app is also constrained < 1400px.

@media screen and (min-width: 1600px) {
  .Style {
    flex-basis: 16.66667%;
  }
}

@media screen and (min-width: 1920px) {
  .Style {
    flex-basis: 14.2857%;
  }
}

@media screen and (min-width: 2560px) {
  .Style {
    flex-basis: 12.5%;
  }
}

*/
