.Badge {
  background: #fff;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  color: #8f8f8f;
  display: block;
  font-size: 0.75rem;
  font-weight: bold;
  height: 16px;
  right: 0.25rem;
  line-height: 1rem;
  padding-top: 0.5rem;
  position: absolute;
  top: 0px;
  width: 1rem;
}
