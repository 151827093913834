.ProductImage {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #EBEBEB;
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
  height: 85vh;
  left: 7.5vw;
  position: fixed;
  width: 85vw;
  top: 7.5vh;
  z-index: 100;
}

.ProductImage-image-container {
  margin: 5vh 5vw;
  height: 70vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.ProductImage button {
  background: #FFB91D;
  border: none;
  color: #000;
  cursor: pointer;
  height: 3.75rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 3.75rem;
  z-index: 1;
}
