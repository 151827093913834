.Menu {
  background: #fff;
  box-sizing: border-box;
  min-height: calc(50vh - 1.875rem);
  padding: 1rem 1.5rem;
  position: absolute;
  top: 3.75rem;
  width: 100%;
  z-index: 1000;
}

.Menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 768px) {
  .Menu {
    padding: 1rem 5.5rem 2rem 5.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .Menu {
    float: left;
    height: calc(100vh - 45px);
    overflow-y: auto;
    top: 3.75rem;
    width: 50%;
  }
}
