.Results-container {
  overflow: auto;
  position: relative;
}

table.Results {
  border-collapse: collapse;
  width: 100%;
}

.Results th, .Results td {
  font-size: 1rem;
}

.Results th {
  background: #f5f5f5;
  border-right: 1px solid #fff;
  text-align: left;
  padding: 0.75rem;
  position: relative;
}

.Results-sliver {
  background: #ffb91d;
  bottom: 0rem;
  display: block;
  height: 0.25rem;
  left: 0;
  position: absolute;
  right: 0;
}

.Results thead tr {
  border-bottom: 0.375rem solid #fff;
}

.Results tbody td {
  border-bottom: 0.1875rem solid #fff;
  border-right: 1px solid #fff;
}

.Results td {
  background: #ebebeb;
  padding: 0.75rem;
}

.Results tr:nth-child(even) td {
  background: #f5f5f5;
}
