.BackButton {
  background: #FFB91D;
  border: none;
  color: #000;
  cursor: pointer;
  font-size: 0.75rem;
  height: 3.75rem;
  margin-left: -1.6667rem;
  margin-bottom: 0.5rem;
  outline: none;
  text-transform: uppercase;
  width: 3.75rem;
}

.BackButton:hover,
.BackButton:focus {
  background: #FA9E0B;
} 

.BackButton img {
  height: 1.333rem;
  margin: 0.333rem 0 0 0;
  padding: 0.25rem 0;
}

@media screen and (min-width: 768px) {
  .BackButton {
    left: 0;
    margin: 0;
    position: absolute;
  }
}
