@media screen and (max-width: 680px) {
  .ResultsHeading {
    position: relative;
  }

  .ResultsHeading::after {
    width: 3rem;
    height: 3rem;
    background: url(swipe.svg) no-repeat center center;
    content: " ";
    position: absolute;
    right: 0;
    top: 0;
    margin-top: -1rem;
  }
}
