.DefaultButton {
  background: #ffb91d;
  border: 0.125rem solid #ffb91d;
  border-radius: 0.25rem;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  height: 4rem;
  line-height: 4rem;
  margin-top: 1.25rem;
  outline: none;
  padding: 0 2rem;
  text-align: center;
  width: 100%;
}

.DefaultButton:not(.DisabledButton):focus,
.DefaultButton:not(.DisabledButton):hover {
  background: #FA9E0B;
  border-color: #FA9E0B;
}

a.DefaultButton {
  text-decoration: none;
}
