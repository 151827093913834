.ElementImages {
  position: relative;
}

.ElementImages-image {
  margin: 1rem auto 0.25rem auto;
  text-align: center;
  width: 80%;
}

.ElementImages-image img {
  max-width: 100%;
}

.ElementImages-button {
  border: none;
  cursor: pointer;
  display: block;
  height: 3.75rem;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-appearance: none;
  width: 3.75rem;
}

.ElementImages-button-previous {
  left: 0;
}

.ElementImages-button-next {
  right: 0;
}

@media screen and (min-width: 768px) {
  .ElementImages-button-previous {
    left: -5.5rem;
  }

  .ElementImages-button-next {
    right: -2rem;
  }
}
