.CannotFind-preview {
  background: #fff;
  border: 1px solid #000;
  min-height: 5.5rem;
  width: 5.5rem;
}

.CannotFind-preview img {
  display: block;
  width: 100%;
}

.FormWell {
  background: #f5f5f5;
  border-radius: 0.333rem;
  margin: 1rem 0;
  padding: 1rem;
}
