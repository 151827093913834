.ProductInfo-parker-number {
  font-size: 2.5rem;
  margin-top: 4.5rem;
}

.ProductInfo-table {
  border-collapse: collapse;
  width: 100%;
}

.ProductInfo-table th {
  text-align: left;
}

.ProductInfo-table td, .ProductInfo-table th {
  border-bottom: 1px solid #EBEBEB;
  padding: 0.5rem 0 2rem 0;
}

.ProductInfo-buttons {
  display: flex;
  margin: 0 -0.25rem;
  padding-top: 1rem;
}

.ProductInfo-buttons > button,
.ProductInfo-buttons > div {
  flex-basis: 50%;
  margin: 0 0.25rem;
}
