.InputField, .RemovableInputField input {
  background-color: #f5f5f5;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  box-sizing: border-box;
  color: #000;
  font-size: 1rem;
  height: 3.6667rem;
  outline: none;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
}

.InputField:hover, .InputField:focus {
  background-color: #ebebeb;
}
