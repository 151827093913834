.MenuItem {
  margin: 0;
  padding: 0;
}

.MenuItem a {
  border-bottom: 1px solid #FA9E0B;
  display: block;
  font-size: 1.667rem;
  line-height: 2rem;
  padding: 1rem 2.333rem 1rem 2.333rem;
  position: relative;
  text-decoration: none;
}

.MenuItem-arrow {
  height: 1rem;
  position: absolute;
  right: 0.75rem;
  top: 1.5rem;
}

.MenuItem a:hover .MenuItem-arrow,
.MenuItem a:focus .MenuItem-arrow {
  right: 0.5rem;
}

.MenuItem-badge-count {
  background-color: #FFB91D;
  border-radius: 0.6667rem;
  color: #fff;
  display: block;
  height: 1.333rem;
  font-size: 0.8333rem;
  left: 0.25rem;
  line-height: 1.333rem;
  margin-top: 0.25rem;
  text-align: center;
  width: 1.333rem;
  position: absolute;
}

.MenuItem-badge-count0 {
  display: none;
}
