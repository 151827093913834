.TickCross {
  cursor: help;
  text-align: center;
}

.TickCross-tooltip {
  background-color: #fa9e0b !important;
  border-radius: 7px !important;
  color: #000 !important;
  font-size: 0.8333rem;
  font-weight: bold;
  max-width: 150px;
}

.TickCross-tooltip.place-bottom:after {
  border-bottom-color: #fa9e0b !important;
}
