.VideoTutorial-layout {
  margin: 0 auto;
  max-width: 75%;
}

.VideoTutorial-wrapper {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.VideoTutorial-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
