.Dimensions-line-drawing img {
  display: block;
  margin: 0 auto;
  height: 70vh;
  max-width: 100%;
}

.Dimension {
  display: flex;
}

.Dimension > div {
  flex-basis: 70%;
}

.Dimension > div + div {
  flex-basis: 30%;
  padding-left: 1rem;
}
