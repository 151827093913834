.PlusMinus {
  background: #ffb91d;
  border-radius: 0.9375rem;
  color: #fff;
  font-size: 1.875rem;
  font-weight: bold;
  height: 1.875rem;
  line-height: 1.625rem;
  margin: 0 auto;
  width: 1.875rem;
}

.PlusMinus:hover, .PlusMinus:focus {
  background: #FA9E0B;
}
