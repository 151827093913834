.CannotFindLinkBlock {
  margin-top: 1rem;
}

.CannotFindLinkBlock h3 {
  margin: 1rem 0;
}

.CannotFindLinkBlock .DefaultButton {
  margin-top: 0;
}

@media screen and (min-width: 768px) {
  .CannotFindLinkBlock .FormGroup {
    display: flex;
    justify-content: space-between;
  }

  .CannotFindLinkBlock h3 {
    flex-basis: 50rem;
  }

  .CannotFindLinkBlock .DefaultButton {
    flex-basis: 30rem;
  }
}
