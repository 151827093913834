.FullPanel {
  background: #fff;
  box-sizing: border-box;
  min-height: calc(100vh - 45px);
  padding: 1.6667rem;
}

@media screen and (min-width: 768px) {
  .FullPanel {
    padding: 3.5rem 2rem 2rem 5.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .FullPanel {
    overflow-y: auto;
  }
}
