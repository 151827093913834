.LoadingButton {
  animation-name: loading;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

@keyframes loading {
  0% {
    background-color: #FFB91D;
    border-color: #FFB91D;
  }
  50% {
    background-color: #ffd372;
    border-color: #ffd372;
  }
  100 {
    background-color: #FFB91D;
    border-color: #FFB91D;
  }
}
