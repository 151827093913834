.Enquiries-table tr > td {
  font-weight: bold;
}

.Enquiries-table input, .Enquiries-table select {
  background: #fff;
}

.Enquiries-table input:hover, .Enquiries-table input:focus {
  background: #f5f5f5;
}

.Enquiries-table input {
  width: 3.75rem;
}

@media screen and (min-width: 1200px) {
  .Enquiries-table input {
    width: 5.625rem;
  }
}
