.RemovableInputField {
  margin: 0.75rem 0;
}

.RemovableInputField input {
  box-sizing: border-box;
  width: calc(100% - 3rem);
}

.rbt {
  display: inline-block;
  width: calc(100% - 3rem) !important;
}

.rbt-menu {
  background: #f5f5f5;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  border-top: none;
  box-sizing: border-box;
  margin: -0.25rem 0 0 0;
  width: calc(100% - 3rem) !important;
  z-index: 100;
}

.rbt-menu li.disabled {
  display: none;
}

.rbt-menu a {
  display: block;
  font-weight: normal;
  padding: 1rem;
  text-decoration: none;
}

.rbt-menu a:hover {
  background: #ebebeb;
}

.rbt-menu a mark {
  font-weight: bold;
  background: transparent;
}
