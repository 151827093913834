.Error {
  background: #DD1F1F;
  border-radius: 0.25rem;
  color: #fff;
  font-weight: bold;
  min-height: 3.667rem;
  padding: 0.667rem 1rem 0.667rem 4.5rem;
  position: relative;
}

.Error-exclamation {
  height: 2.333rem;
  left: 1rem;
  margin-top: -1.167rem;
  position: absolute;
  top: 50%;
  width: 2.333rem;
}
