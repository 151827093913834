.RightPanel {
  min-height: calc(50vh - 1.875rem);
}

@media screen and (min-width: 1200px) {
  .RightPanel {
    box-sizing: border-box;
    float: left;
    min-height: calc(100vh - 3.75rem);
  }
}
