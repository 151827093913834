.LabeledSelectField-field {
  background: #f5f5f5;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  box-sizing: border-box;
  color: #000;
  display: block;
  font-size: 1rem;
  height: 3.75rem;
  line-height: 1rem;
  outline: none;
  width: 100%;
}
