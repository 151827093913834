.WidePanel {
  background: #fff;
}

@media screen and (min-width: 1200px) {
  .WidePanel {
    border-left: 1px solid #F5F5F5;
    width: 75%;
  }
}
