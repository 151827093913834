.RadioButton {
  background: none;
  border: none;
  cursor: pointer;
  display: block;
  line-height: 2.25rem;
  outline: none;
}

.RadioButton img {
  height: 2.25rem;
  vertical-align: middle;
  width: 2.25rem;
}
